<template>
  <div>
    <!------ plainform area start ------->
    <section class="plainform-area">
      <div class="container">
        <price-one-app
          v-for="(item, index) in roomsList"
          :key="index"
          :data="item"
          :index="index"
          :hotels="hotels"
          :translations="translations"
          :showRateRoom="roomsList.length>1"
          @closed="removeItem"
          @update="updateItem"
          @setPriceLoading="getPriceLoading"
        />
        <div class="btn-group">
          <button class="btn-primary"  @click="addRoom" :disable="priceLoading">
            <b-spinner small type="grow" v-if="priceLoading"></b-spinner>
            <p v-else>הוסף חדר +</p>
          </button>
        </div>
      </div>
    </section>
    <!------ plainform area stop ------->
    <!----- order area start ----->
    <section class="order-area">
      <div class="container">
        <div class="order-list">
          <ul>
            <li>
              {{ $t('product-page.average-per-passenger') }}
              <strong>{{ getPriceWithSymbol( product.cc, averagePrice) }}</strong>
            </li>
            <li>
              {{ $t('product-page.total-pay') }}
              <strong>{{ getPriceWithSymbol( product.cc, totalPrice) }}</strong>
            </li>
          </ul>
        </div>
        <div class="btn-box">
          <button class="btn-primary" :disabled="disableBook" @click="gotoBook">הזמן עכשיו</button>
        </div>
      </div>
    </section>
    <!----- order area stop ----->
  </div>
</template>
<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { BSpinner } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';
import googleAnalytics from '@/utils/googleAnalytics';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  components: {
    PriceOneApp: () => import('@/components/productPage/PriceOneApp'),
    BSpinner,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    hotelName: {
      type: String,
      default: '',
    },
    destinationCityName: {
      type: String,
      default: '',
    },
    includesText: {
      type: String,
      default: '',
    },
  },
  mixins: [gMixin, googleAnalytics],
  data() {
    return {
      searchQuery: '',
      query: null,

      personString: '',

      roomsList: [],
      maxRoomCount: 5,
      addRoomDisable: false,
      priceLoading: false,

      roomBasisCode: null,
      adult: 0,
      child: 0,
      infant: 0,
      totalPrice: 0,
      averagePrice: 0,
      translations: [],

      disableBookDiscount: false,
      disableBook: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      typeChannel: 'GET_TYPE_CHANNEL',
      flights: 'GET_FLIGHT_ID',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    packageType() {
      const result = (this.categoryCode === 'Organize_tour_packages')
        ? `${this.$t('product-page.organized-trip')} ( ${this.$t('product-page.organized-trip-price-items')})`
        : `${this.$t('product-page.includes-message')}${this.includesText}`;
      return result;
    },
    categoryCode() {
      return this.product?.category?.code ?? '';
    },
    daysString() {
      return this.getBetweenDateString(this.query.dateFrom, this.product.hotel_shift_1, this.product.duration_1, this.product.hotels[0].duration);
    },
    disableBookingOnAPP() {
      return this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP;
    },
  },
  watch: {
    roomsList() {
      this.paxesDisable = this.roomsList.length === this.maxRoomCount;
      this.updateTotalPrice();
    },
    lang() {
      this.personString = this.getPersonString(
        this.adult,
        this.child,
        this.infant,
      );
    },
  },
  beforeMount() {
    this.hotels = this.product.hotels;
    this.translations = this.product.translations;

    this.query = this.$route.query;
    const { adult, child, infant } = this.query;
    this.adult = Number(adult);
    this.child = Number(child);
    this.infant = Number(infant);

    this.personString = this.getPersonString(
      this.adult,
      this.child,
      this.infant,
    );
    this.roomsList.push(this.getOneItem());
  },
  methods: {
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    getBetweenDateString(pFromDate, pShift, pDuration, pNight) {
      const checkIn = dayjs(pFromDate).add(Number(pShift), 'day'),
        checkOut = checkIn.add(Number(pDuration), 'day');
      let tripDuration = '', betweenDateString = '';
      if (this.lang === 'he') {
        tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} - ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${pNight} ${this.$t('search-result.night')})`;
        betweenDateString = tripDuration;
      } else {
        tripDuration = `${this.getWeek(checkIn)} ${dayjs(checkIn).format('DD/MM/YY')} - 
            ${this.getWeek(checkOut)} ${dayjs(checkOut).format('DD/MM/YY')} (${pNight} ${this.$t('search-result.night')})`;
        betweenDateString = tripDuration;
      }
      return betweenDateString;
    },
    getPersonString(adult, child, infant) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    removeItem(index) {
      this.roomsList.splice(index, 1);
    },
    async addRoom() {
      if (!this.addRoomDisable) {
        this.roomsList.push(this.getOneItem());
        this.updateRoomsState();
      }
    },
    updateRoomsState() {
      const paxes = {
        adult: 0, child: 0, infant: 0,
      };
      this.roomsList.forEach((room) => {
        paxes.adult += room.adult;
        paxes.child += room.child;
        paxes.infant += room.infant;
      });
      this.$emit('message', { kind: 'upgradeRooms', paxes });
    },
    async updateItem(priceInfo) {
      if (priceInfo.success) {
        // if (priceInfo.price !== 0) {
        const flagUpdateBasis = this.roomBasisCode ? priceInfo.roomBasis !== this.roomBasisCode.value : false;
        for (const key in this.roomsList[priceInfo.index]) {
          this.roomsList[priceInfo.index][key] = priceInfo[key];
        }
        if (flagUpdateBasis) {
          for (let i = 0; i < this.roomsList.length; i += 1) {
            this.roomsList[i].roomBasis = priceInfo.roomBasis;
          }
        }
        this.averagePrice = 0;
        for (let i = 0; i < this.roomsList.length; i += 1) {
          this.averagePrice += this.roomsList[i].price;
        }
        this.averagePrice = Math.round(this.averagePrice / this.roomsList.length);
        this.updateTotalPrice();
        this.roomBasisCode = priceInfo.roomBasisCode;
        this.updateRoomsState();
      } else {
        this.disableBookDiscount = true;
        this.$emit('message', priceInfo.message);
      }
    },
    getOneItem() {
      return {
        hotelId: '',
        packSelectionId: this.product.packSelectionId,
        roomClass: { text: '', value: '' },
        roomType: { text: '', value: '' },
        roomBasis: this.roomBasisCode ? this.roomBasisCode.value : '',
        roomBasisCode: this.roomBasisCode,
        price: 0,
        adult: 0,
        child: 0,
        infant: 0,
        totalPrice: 0,
        oldPrice: 0,
        oldTotal: 0,
        priceInf: 0,
        cc: this.product.cc,
      };
    },
    updateTotalPrice() {
      this.totalPrice = this.roomsList.reduce((a, b) => +a + +b.totalPrice, 0);
      this.product.priceTotal = this.totalPrice;
      window.sessionStorage.setItem('production', JSON.stringify(this.product));
      this.$store.dispatch('UPDATE_ROOM_LIST', { roomList: this.roomsList });
    },
    getPriceLoading(isLoading) {
      this.priceLoading = isLoading;
      this.disableBook = isLoading || this.disableBookDiscount;
    },
    async gotoBook() {
      const selectedRooms = [],
        gHotel = this.product.hotels[0];
      let discountedTotal = 0, undiscountedTotal = 0, totalPerson = 0;
      this.roomsList.forEach((room) => {
        const roomData = {
          hotelId: Number(room.hotelId),
          cityCode: gHotel.city,
          hotelSupplierCode: gHotel.Supplier,
          basisCode: room.roomBasis,
          rooms: {
            roomClassCode: room.roomClass.value,
            roomTypeCode: room.roomType.value,
            accomodation: 1,
            bBCots: Number(room.infant),
            paxIds: [],
          },
        };
        discountedTotal += room.totalPrice - room.infant * room.priceInf;
        undiscountedTotal += room.oldTotal - room.infant * room.priceInf;
        totalPerson += (room.adult + room.child + room.infant);

        selectedRooms.push(roomData);
      });

      this.product.hotel = selectedRooms;
      this.product.packageId = Number(this.roomsList[0].roomBasisCode.packId);
      this.product.duration = Number(this.product.duration_1);
      this.product.discountPercent = (discountedTotal !== undiscountedTotal) ? ((undiscountedTotal - discountedTotal) * 100) / undiscountedTotal : null;
      this.product.price = Number(this.product.priceTotal);
      this.product.priceNIS = 0;
      this.product.currency = this.product.cc;
      this.product.ensureCancellation = false;
      const data = {
        data: this.product,
        sendingStage: 'init',
      };
      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);

      if (this.availableEmitEventGA4ByDomain()) {
        const affiliation = 'BO NAUF';
        const currencyCode = this.convertCurrencyCode(this.product.cc);
        this.gtag('event', 'view_item', {
          currency: currencyCode,
          value: this.totalPrice,
          items: [
            {
              item_id: response.data.bookingTransactionId,
              item_name: `${this.hotelName} - ${this.destinationCityName}`,
              affiliation,
              coupon: '',
              discount: '',
              index: 0,
              item_brand: '',
              item_category: this.destinationCityName,
              item_category2: this.getCheckInOut(),
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: this.totalPrice,
              quantity: 1,
            },
          ],

          // items: [
          //   {
          //     id: response.data.bookingTransactionId, // please assign number
          //     name: `${this.hotelName} - ${this.destinationCityName}`,
          //     list_name: '',
          //     brand: '',
          //     category: '',
          //     variant: '',
          //     list_position: 1,
          //     quantity: 1,
          //     price: this.totalPrice,
          //   },
          // ],
        });
      }

      if (response.success) {
        this.product.priceNIS = response.data.priceNis;
        window.sessionStorage.setItem('production', JSON.stringify(this.product));
        window.sessionStorage.setItem('BookingDataID', response.data.bookingTransactionId);
        window.sessionStorage.setItem('retryState', 'null');
        window.sessionStorage.setItem('cancellationInfo', JSON.stringify({
          cancellationAllowed: this.product.cancellationAllowed,
          cancelInsuranceFee: response.data.cancelInsuranceFee,
          cancelInsuranceFeeNis: response.data.cancelInsuranceFeeNis,
          totalPerson,
        }));
        const body = {
          packID: this.product.packageId,
          laID: this.query.laId,
          flights: this.flights,
          marketerId: this.query.marketerId,
          channel: this.typeChannel,
        };
        if (this.$route.query.utm_source) body.utm_source = this.$route.query.utm_source;
        if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';
        this.$router.push({ path: '/booking/hotel-flight/app', query: body });
      } else {
        window.sessionStorage.setItem('BookingDataID', '');
      }
    },
  },
};
</script>
<style scoped>
button:disabled,
[type='button']:disabled,
[type='reset']:disabled,
[type='submit']:disabled {
  cursor: default;
  opacity: 0.2;
}
</style>
<style>
button.js-del-row {
  top: 5px;
}

@media (max-width: 600px) {
  div[id^='upgrade-'] .modal-dialog {
    width: 96%;
  }
  div[id^='upgrade-'] table th,
  div[id^='upgrade-'] table td {
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .flightpagelist.filterItem {
    margin-top: 1em;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 5px;
  }
}
</style>
