import { render, staticRenderFns } from "./ProductPriceAreaApp.vue?vue&type=template&id=71657957&scoped=true"
import script from "./ProductPriceAreaApp.vue?vue&type=script&lang=js"
export * from "./ProductPriceAreaApp.vue?vue&type=script&lang=js"
import style0 from "./ProductPriceAreaApp.vue?vue&type=style&index=0&id=71657957&prod&scoped=true&lang=css"
import style1 from "./ProductPriceAreaApp.vue?vue&type=style&index=1&id=71657957&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71657957",
  null
  
)

export default component.exports